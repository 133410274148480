export enum ToolbarFiltersTypes {
  courses = 'Courses',
  coursesLocal = 'CoursesLocal',
  trainings = 'Trainings',
  trainingsLocal = 'trainingsLocal',
  trainingsEvents = 'TrainingsEvents',
  learningPaths = 'LearningPaths',
  learningPathsLocal = 'LearningPathsLocal',
  courseActivities = 'CourseActivities',
  trainingSchedules = 'TrainingSchedules',
  cohorts = 'Cohorts',
  languages = 'Languages',
  categories = 'Categories',
  courseStatus = 'CourseStatus',
  trainingStatus = 'TrainingStatus',
  type = 'Type',
  author = 'Author',
  members = 'Members',
  emailTemplates = 'EmailTemplates',
  emailTemplatesType = 'EmailTemplatesType',
  groups = 'Groups',
  hireDate = 'HireDate',
  memberStatus = 'MemberStatus',
  checkUserName = 'CheckUserName',
}
