<div class="filters-container" [class.di-none]="!expandFilters" fxLayout="row" fxLayoutAlign="flex-start center">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px" class="toolbar-table-filters">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
      <div *ngIf="filters?.includes(ToolbarFiltersTypes.type)">
        <mat-form-field appearance="outline" class="mb-0">
          <mat-label>{{ 'reusable.trainingType' | translate }}</mat-label>
          <mat-select [(ngModel)]="trainingType" (selectionChange)="onTrainingType($event)">
            <mat-option [value]="null">-</mat-option>
            <mat-option [value]="sections.COURSES" style="text-transform: capitalize;">{{ 'sections.courses' | translate }}</mat-option>
            <mat-option [value]="sections.ILT_COURSES" style="text-transform: capitalize;">{{ 'sections.iltCourses' | translate }}</mat-option>
            <mat-option [value]="sections.LEARNING_PATHS" style="text-transform: capitalize;">{{ 'sections.learningPaths' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <app-multi-select-server-side-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.courses)"
        #courseFilter
        [className]="Course"
        [initialIds]="storedTrainingsFilters?.filter?.course_ids"
        [placeholder]="'sections.courses' | translate"
        [placeholderLabel]="'filters.coursesPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        [preventInitialEmitEvent]="true"
        (selectEvent)="onCourseFilter($event, Course)"
      ></app-multi-select-server-side-search>
      <app-multi-select-local-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.coursesLocal)"
        #courseLocalFilter
        [className]="Course"
        [initialIds]="storedTrainingsFilters?.filter?.course_ids"
        [resources]="coursesLocalResources"
        [placeholder]="'sections.courses' | translate"
        [placeholderLabel]="'filters.coursesPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        (selectResources)="doLocalFilterCourses($event)"
      ></app-multi-select-local-search>
      <app-multi-select-server-side-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.trainings)"
        #iltCourseFilter
        [className]="ILTCourse"
        [includes]="filters?.includes(ToolbarFiltersTypes.trainingsEvents) ? 'events' : ''"
        [initialIds]="storedTrainingsFilters?.filter?.ilt_course_ids"
        [placeholder]="'sections.iltCourses' | translate"
        [placeholderLabel]="'filters.coursesPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        [preventInitialEmitEvent]="true"
        (selectEvent)="onIltFilter($event)"
      ></app-multi-select-server-side-search>
      <app-multi-select-local-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.trainingsLocal)"
        #iltCourseLocalFilter
        [className]="ILTCourse"
        [initialIds]="storedTrainingsFilters?.filter?.ilt_course_ids"
        [resources]="iLTCoursesLocalResources"
        [placeholder]="'sections.iltCourses' | translate"
        [placeholderLabel]="'filters.iltCoursesPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        (selectResources)="onIltLocalFilter($event)"
      ></app-multi-select-local-search>
      <app-multi-select-local-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.trainingsEvents) && iltCourseFilter?.multiSelect?.value?.length"
        #eventsFilter
        [className]="ILTCourseEvent"
        [resources]="iltEventsResources"
        [placeholder]="'sections.events' | translate"
        [placeholderLabel]="'filters.eventsPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        (selectResources)="doFilterEvents($event)"
      ></app-multi-select-local-search>
      <app-multi-select-server-side-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.learningPaths)"
        #learningPathFilter
        [className]="LearningPath"
        [initialIds]="storedTrainingsFilters?.filter?.learning_path_ids"
        [placeholder]="'sections.learningPaths' | translate"
        [placeholderLabel]="'filters.learningPathsPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        (selectEvent)="onLPFilter($event, LearningPath)"
      ></app-multi-select-server-side-search>
      <app-multi-select-local-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.learningPathsLocal)"
        #learningPathLocalFilter
        [className]="LearningPath"
        [initialIds]="storedTrainingsFilters?.filter?.learning_path_ids"
        [resources]="learningPathLocalResources"
        [placeholder]="'sections.learningPaths' | translate"
        [placeholderLabel]="'filters.learningPathsPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        (selectResources)="doFilterLPLocal($event)"
      ></app-multi-select-local-search>
      <app-multi-select-local-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.categories)"
        #categoryLocalFilter
        [className]="CourseCategory"
        [resources]="allCategories"
        [initialIds]="storedTrainingsFilters?.filter?.course_category_ids"
        [placeholder]="'sections.categories' | translate"
        [placeholderLabel]="'filters.categoriesPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        (selectResources)="doFilterCategories($event)"
      ></app-multi-select-local-search>
      <app-multi-select-server-side-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.courseActivities)"
        #courseActivityFilter
        [className]="CourseActivity"
        [initialIds]="storedTrainingsFilters?.filter?.course_activity_ids"
        [placeholder]="'filters.tasksPlaceholder' | translate"
        [placeholderLabel]="'filters.tasksPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        [initialFilters]="{ own_task_activity: true }"
        (selectEvent)="onFilterCourseActivities($event)"
      ></app-multi-select-server-side-search>
      <app-multi-select-server-side-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.members)"
        #memberFilter
        [className]="Member"
        [initialIds]="storedTrainingsFilters?.filter?.member_ids"
        [placeholder]="'filters.talentsPlaceholder' | translate"
        [placeholderLabel]="'filters.talentsPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        (selectEvent)="onFilterMembers($event)"
      ></app-multi-select-server-side-search>
      <app-multi-select-server-side-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.trainingSchedules)"
        #scheduleFilter
        [className]="TrainingSchedule"
        [initialIds]="storedTrainingsFilters?.filter?.schedule_ids"
        [placeholder]="'sections.trainingSchedules' | translate"
        [placeholderLabel]="'filters.trainingSchedulesPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        (selectEvent)="onFilterTrainingSchedules($event)"
      ></app-multi-select-server-side-search>
      <app-multi-select-server-side-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.cohorts)"
        #scheduleCohortsFilter
        [className]="TrainingScheduleCohort"
        [placeholder]="'sections.trainingScheduleCohorts' | translate"
        [placeholderLabel]="'filters.trainingScheduleCohortsPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        [initialFilters]="{ schedule_id: scheduleCohortFilterId }"
        (selectEvent)="onFilterTrainingScheduleCohorts($event)"
      ></app-multi-select-server-side-search>
      <div *ngIf="filters?.includes(ToolbarFiltersTypes.courseStatus)">
        <mat-form-field appearance="outline" class="status-filter">
          <mat-label>{{ 'courses.courseStatus' | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedCourseStatus" (selectionChange)="onCourseStatusChanged($event)" [multiple]="true">
            <mat-option [value]="CourseProgressStatus.new" style="text-transform: capitalize;">{{ 'common.new' | translate }}</mat-option>
            <mat-option [value]="CourseProgressStatus.running" style="text-transform: capitalize;">{{ 'courses.status.running' | translate }}</mat-option>
            <mat-option [value]="CourseProgressStatus.finished" style="text-transform: capitalize;">{{ 'courses.status.finished' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="filters?.includes(ToolbarFiltersTypes.trainingStatus)">
        <mat-form-field appearance="outline" class="status-filter">
          <mat-label>{{ 'iltCourses.status.label' | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedTrainingStatus" (selectionChange)="onTrainingStatusChanged($event)" [multiple]="true">
            <mat-option [value]="ILTCourseStatus.assigned" style="text-transform: capitalize;">{{ 'iltCourses.status.assigned' | translate }}</mat-option>
            <mat-option [value]="ILTCourseStatus.subscribed" style="text-transform: capitalize;">{{ 'iltCourses.status.subscribed' | translate }}</mat-option>
            <mat-option [value]="ILTCourseStatus.completed" style="text-transform: capitalize;">{{ 'courses.status.finished' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="filters?.includes(ToolbarFiltersTypes.languages)">
        <mat-form-field appearance="outline" class="mb-0">
          <mat-label>{{ 'filters.language' | translate }}</mat-label>
          <mat-select [multiple]="true" [(ngModel)]="filterLanguage" (selectionChange)="onFilterLanguage($event)">
            <mat-option *ngFor="let country of countries; let i = index" [value]="country">
              <app-elearnio-language-span [countryCode]="country"></app-elearnio-language-span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <app-multi-select-server-side-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.emailTemplates)"
        #customEmailNameSearch
        [initialIds]="storedTrainingsFilters?.filter?.email_ids"
        [className]="CustomEmail"
        [initialFilters]="{ email_language: filterLanguage ? filterLanguage : []}"
        [placeholder]="'settings.customEmailList.templateName' | translate"
        [placeholderLabel]="'settings.customEmailList.templateName' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        (selectEvent)="onCustomEmailFilter($event)"
      ></app-multi-select-server-side-search>
      <app-multi-select-server-side-search
        *ngIf="filters?.includes(ToolbarFiltersTypes.groups)"
        #groupFilter
        [className]="Group"
        [initialIds]="storedTrainingsFilters?.filter?.group_ids"
        [placeholder]="'sections.groups' | translate"
        [placeholderLabel]="'filters.groupsPlaceholder' | translate"
        [noEntriesFoundLabel]="'filters.noDataFound' | translate"
        (selectEvent)="onGroupFilter($event)"
      ></app-multi-select-server-side-search>
      <mat-slide-toggle
        *ngIf="filters?.includes(ToolbarFiltersTypes.author)"
        [(ngModel)]="authorChecked" 
        (change)="onAuthorToggleChange($event)"
        labelPosition="after"
      >
        {{ 'courses.admin-list.my-courses' | translate }}
      </mat-slide-toggle>
      <div *ngIf="filters?.includes(ToolbarFiltersTypes.emailTemplatesType)">
        <mat-form-field 
        appearance="outline" 
        class="mat-form-field-filter"
        >
          <mat-label>{{ 'settings.customEmailList.templateType' | translate }}</mat-label>
          <mat-select
            #allTypes
            [ngModel]="filterCustomEmailsType"
            [multiple]="true"
            [compareWith]="compareEmailTypes"
            (selectionChange)="onEmailTypeFilter($event)"
          >
            <mat-option *ngFor="let type of customEmailTypes | keyvalue; let i = index" [value]="type">
                {{type.value | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="filters?.includes(ToolbarFiltersTypes.hireDate)">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'settings.personal.hireDate' | translate }}</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [value]="startDate" [placeholder]="'common.startDate' | translate" (dateChange)="onHireStartDateFilter($event)">
            <input matEndDate [value]="endDate" [placeholder]="'common.endDate' | translate" (dateChange)="onHireEndDateFilter($event)">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div *ngIf="filters?.includes(ToolbarFiltersTypes.memberStatus)">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'reporting.header.userStatusLabel' | translate }}</mat-label>
          <mat-select [(ngModel)]="talentStatusFilter" multiple (selectionChange)="onTalentStatusFilter($event)">
            <mat-option *ngFor="let status of talentStatuses" [value]="status" style="text-transform: capitalize;">
              {{'talents.profile.statuses.' + status | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="filters?.includes(ToolbarFiltersTypes.checkUserName) && enableLoginUsername" class="check-users-with-username" fxLayoutGap="12px" fxLayoutAlign="flex-start center">
        <mat-slide-toggle
          [checked]="checkUsersWithUsername"
          (change)="changeCheckUsersWithUsername($event)"
        >
        </mat-slide-toggle>
        <span>
          {{ 'reporting.header.checkUserWithUsername' | translate }}
        </span>
      </div>
    </div>
    <button *ngIf="filters" mat-stroked-button color="darkblue" class="no-border reset-filters-btn" (click)="onResetFilters()">
      <mat-icon class="size-sm">filter_list_off</mat-icon>
      {{ 'common.reset' | translate }}
    </button>
  </div>
</div>