import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { CourseCategory } from '@app/models/course-category.model';
import { Course } from '@app/models/course.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Resource } from '../multi-select-server-side-search/interfaces';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MultiSelectServerSideSearchComponent } from '../multi-select-server-side-search/multi-select-server-side-search.component';
import { LocalStorageService } from '@app/core/services/operations.service';
import { TrainingsFilters } from '@core/constants/training-filters.constants';
import { ToolbarFiltersTypes } from '@core/constants/toolbar-filters.constants';
import { ToolbarFiltersService } from '@app/core/services/toolbar-filters.service';
import { ILTCourse } from '@app/models/ilt-course.model';
import { CourseCategoryHelper } from '@app/core/helper/course-category.helper';
import { Datastore } from '@app/core/services/datastore';
import { MultiSelectLocalSearchComponent } from '../multi-select-local-search/multi-select-local-search.component';
import { LearningPath } from '@app/models/learning-path.model';
import { MatSelectChange } from '@angular/material/select';
import { LANGUAGES } from '@app/core/constants/language.constants';
import { Member } from '@app/models/member.model';
import { CourseActivity } from '@app/models/course/course-activity.model';
import { ILTCourseEvent } from '@app/models/ilt-course/ilt-course-event.model';
import { Section } from '@app/core/constants/section.constants';
import { CourseProgressStatus } from '@app/core/pipes/course-progress-status/course-progress-status.pipe';
import { FiltersResources } from '@app/core/interfaces/filters-resources.interface';
import { ILTCourseStatus } from '@app/core/helper/ilt-course-filter-helper';
import { TrainingSchedule } from '@app/models/training-schedule/training-schedule.model';
import { TrainingScheduleCohort } from '@app/models/training-schedule/training-schedule-cohort.model';
import { CustomEmail } from '@app/models/custom-email/custom-email.model';
import { Group } from '@app/models/group.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { TalentStatuses } from '@app/core/constants/talent-statuses.constant';

@UntilDestroy()
@Component({
  selector: 'app-toolbar-filters',
  templateUrl: './app-toolbar-filters.component.html',
  styleUrls: ['./app-toolbar-filters.component.scss'],
})
export class AppToolbarFiltersComponent implements OnInit, OnChanges {
  @Input() filters: string[] | undefined = [];
  @Input() storeId: string | undefined = '';
  @Input() expandFilters = false;

  @Output() doFilters = new EventEmitter<any>();
  @Output() resetFilters = new EventEmitter<any>();
  @Output() filterCounter = new EventEmitter<number>();

  // Server side search
  @ViewChild('courseFilter', { static: false })
  courseFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('iltCourseFilter', { static: false })
  iltCourseFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('learningPathFilter', { static: false })
  learningPathFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('courseActivityFilter', { static: false })
  courseActivityFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('scheduleFilter', { static: false })
  scheduleFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('scheduleCohortsFilter', { static: false })
  scheduleCohortsFilter!: MultiSelectServerSideSearchComponent;
  @ViewChild('customEmailNameSearch', { static: false })
  customEmailNameSearch!: MultiSelectServerSideSearchComponent;
  @ViewChild('groupFilter', { static: false })
  groupFilter!: MultiSelectServerSideSearchComponent;

  // Local search
  @ViewChild('courseLocalFilter', { static: false })
  courseLocalFilter!: MultiSelectLocalSearchComponent;
  @ViewChild('iltCourseLocalFilter', { static: false })
  iltCourseLocalFilter!: MultiSelectLocalSearchComponent;
  @ViewChild('learningPathLocalFilter', { static: false })
  learningPathLocalFilter!: MultiSelectLocalSearchComponent;
  @ViewChild('categoryLocalFilter', { static: false })
  categoryLocalFilter!: MultiSelectLocalSearchComponent;
  @ViewChild('memberFilter', { static: false })
  memberFilter!: MultiSelectLocalSearchComponent;
  @ViewChild('eventsFilter', { static: false })
  eventsFilter!: MultiSelectLocalSearchComponent;

  public Member = Member;
  public Course = Course;
  public ILTCourse = ILTCourse;
  public LearningPath = LearningPath;
  public CourseCategory = CourseCategory;
  public CourseActivity = CourseActivity;
  public ILTCourseEvent = ILTCourseEvent;
  public TrainingSchedule = TrainingSchedule;
  public TrainingScheduleCohort = TrainingScheduleCohort;
  public CustomEmail = CustomEmail;
  public Group = Group;

  public ToolbarFiltersTypes = ToolbarFiltersTypes;
  public sections = Section;
  public CourseProgressStatus = CourseProgressStatus;
  public ILTCourseStatus = ILTCourseStatus;
  public authorChecked = false;

  storedTrainingsFilters: any;
  selectedFilters: any = {};
  allCategories: CourseCategory[] = [];
  filterableEvents: ILTCourseEvent[] = [];
  filterLanguage: string[] | null = null;
  filterCustomEmailsType: string[] = [];
  trainingType = null;
  selectedCourseStatus = [
    CourseProgressStatus.new,
    CourseProgressStatus.running,
  ];
  selectedTrainingStatus = [
    ILTCourseStatus.assigned,
    ILTCourseStatus.subscribed,
  ];
  filterCount = 0;
  countries = [
    LANGUAGES.DE,
    LANGUAGES.EN,
    LANGUAGES.FR,
    LANGUAGES.ES,
    LANGUAGES.IT,
  ];
  defaultTalentStatus: string[] = [
    TalentStatuses.ACTIVE,
    TalentStatuses.ONBOARDING,
    TalentStatuses.PREBOARDING,
  ];
  talentStatuses = Object.values(TalentStatuses);
  customEmailTypes = new Map<string, string>();
  startDate: Date = new Date('');
  endDate: Date = new Date('');
  talentStatusFilter: string[] = [];
  checkUsersWithUsername = false;
  enableLoginUsername = false;

  filterResources!: FiltersResources;
  iltEventsResources!: ILTCourseEvent[];
  coursesLocalResources: Course[] = [];
  iLTCoursesLocalResources: ILTCourse[] = [];
  learningPathLocalResources: LearningPath[] = [];
  scheduleCohortFilterId = '';

  private isResetingFilters = false;
  private isCategoriesLoading = false;

  constructor(
    private storeService: LocalStorageService,
    private datastore: Datastore,
    private toolbarFiltersService: ToolbarFiltersService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.initFilters();
    this.toolbarFiltersService
      .getFilterOverwrite()
      .pipe(untilDestroyed(this))
      .subscribe((resource: FiltersResources) => {
        this.filterResources = resource;
        switch (this.filterResources?.storeId) {
          case TrainingsFilters.instructorsFilters:
            this.iltEventsResources = resource.resources as ILTCourseEvent[];
            break;
          case TrainingsFilters.myIltCoursesFilters:
            if (resource.type === ToolbarFiltersTypes.trainingsLocal) {
              this.iLTCoursesLocalResources = resource.resources as ILTCourse[];
            }
            if (resource.type === ToolbarFiltersTypes.categories) {
              this.allCategories = resource.resources as CourseCategory[];
            }
            break;
          case TrainingsFilters.myLearningPathsFilters:
            if (resource.type === ToolbarFiltersTypes.learningPathsLocal) {
              this.learningPathLocalResources =
                resource.resources as LearningPath[];
            }
            if (resource.type === ToolbarFiltersTypes.categories) {
              this.allCategories = resource.resources as CourseCategory[];
            }
            break;
          case TrainingsFilters.myCoursesFilters:
          case TrainingsFilters.trainingCatalogueFilters:
            if (resource.type === ToolbarFiltersTypes.coursesLocal) {
              this.coursesLocalResources = resource.resources as Course[];
            }
            if (resource.type === ToolbarFiltersTypes.trainingsLocal) {
              this.iLTCoursesLocalResources = resource.resources as ILTCourse[];
            }
            if (resource.type === ToolbarFiltersTypes.learningPathsLocal) {
              this.learningPathLocalResources =
                resource.resources as LearningPath[];
            }
            if (resource.type === ToolbarFiltersTypes.categories) {
              this.allCategories = resource.resources as CourseCategory[];
            }
            break;
          case TrainingsFilters.trainingScheduleCohortsFilters:
            if (resource.type === ToolbarFiltersTypes.cohorts) {
              this.scheduleCohortFilterId = resource.resources as string;
            }
            break;
          case TrainingsFilters.peoplePeopleFilters:
            if (resource.type === ToolbarFiltersTypes.checkUserName) {
              this.enableLoginUsername = resource.resources as boolean;
            }
            break;
        }
      });

    if (this.storeId === TrainingsFilters.emailTemplatesFilters) {
      this.customEmailTypes.set('user', 'main.profile.course.tabControl.user');
      this.customEmailTypes.set('course', 'sections.courses');
      this.customEmailTypes.set('learning_path', 'filters.learningPath');
      this.customEmailTypes.set('live_training', 'sections.iltCourse');
      this.customEmailTypes.set(
        'reminder',
        'main.profile.course.tabSettings.reminderBtn',
      );
      this.customEmailTypes.set('task', 'filters.task');
    }
    if (this.storeId === TrainingsFilters.peoplePeopleFilters) {
      this.talentStatusFilter = this.defaultTalentStatus;
    }
  }

  ngOnChanges(): void {
    if (
      this.storeId === TrainingsFilters.instructorsFilters &&
      this.iltCourseFilter?.initialResources.length > 0
    ) {
      setTimeout(() => {
        this.iltCourseFilter.loadResources();
      }, 0);
    }
    this.initFilters();
  }

  onCourseFilter(
    resources: Resource[],
    className: typeof Course | typeof CourseCategory,
  ): void {
    if (className === Course) {
      this.doFilterCourses(resources as Course[]);
    }
  }

  onIltFilter(resources: Resource[]): void {
    this.doFilterIlts(resources as ILTCourse[]);
  }

  onIltLocalFilter(iltIds: string[]): void {
    this.doLocalFilterIlts(iltIds);
  }

  doFilterEvents(ids: string[]): void {
    if (this.isResetingFilters) {
      return;
    }
    // eslint-disable-next-line camelcase
    this.selectedFilters.ilt_course_event_ids = ids;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onFilterLanguage(languages: MatSelectChange): void {
    if (this.isResetingFilters) {
      return;
    }

    this.filterLanguage = languages.value;
    this.selectedFilters.language = languages.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onLPFilter(
    resources: Resource[],
    className: typeof LearningPath | typeof CourseCategory,
  ): void {
    if (className === LearningPath) {
      this.doFilterLP(resources as LearningPath[]);
    }
  }

  onFilterCourseActivities(resources: Resource[]): void {
    if (this.isResetingFilters) {
      return;
    }
    const courseActivities = resources as CourseActivity[];
    // eslint-disable-next-line camelcase
    this.selectedFilters.course_activity_ids = courseActivities.map(r => r.id);
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onFilterMembers(resources: Resource[]): void {
    if (this.isResetingFilters) {
      return;
    }
    const members = resources as Member[];
    // eslint-disable-next-line camelcase
    this.selectedFilters.member_ids = members.map(r => r.id);
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onFilterTrainingSchedules(resources: Resource[]): void {
    if (this.isResetingFilters) {
      return;
    }
    const trainingSchedules = resources as TrainingSchedule[];
    // eslint-disable-next-line camelcase
    this.selectedFilters.schedule_ids = trainingSchedules.map(r => r.id);
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onFilterTrainingScheduleCohorts(resources: Resource[]): void {
    if (this.isResetingFilters) {
      return;
    }
    const trainingScheduleCohorts = resources as TrainingScheduleCohort[];
    // eslint-disable-next-line camelcase
    this.selectedFilters.cohort_ids = trainingScheduleCohorts.map(r => r.id);
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onCustomEmailFilter(resources: Resource[]): void {
    if (this.isResetingFilters) {
      return;
    }
    const customEmails = resources as CustomEmail[];
    // eslint-disable-next-line camelcase
    this.selectedFilters.email_ids = customEmails.map(r => r.id);
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onGroupFilter(resources: Resource[]): void {
    if (this.isResetingFilters) {
      return;
    }
    const groups = resources as Group[];
    // eslint-disable-next-line camelcase
    this.selectedFilters.group_ids = groups.map(r => r.id);
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onTrainingType(type: MatSelectChange): void {
    if (this.isResetingFilters) {
      return;
    }
    this.trainingType = type.value;
    this.selectedFilters.trainingType = type.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onCourseStatusChanged(status: MatSelectChange): void {
    if (this.isResetingFilters) {
      return;
    }
    this.selectedCourseStatus = status.value;
    // eslint-disable-next-line camelcase
    this.selectedFilters.course_status = status.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onTrainingStatusChanged(status: MatSelectChange): void {
    if (this.isResetingFilters) {
      return;
    }
    this.selectedTrainingStatus = status.value;
    // eslint-disable-next-line camelcase
    this.selectedFilters.selectedStatus = status.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  doFilterCourses(courses: Course[]): void {
    if (this.isResetingFilters) {
      return;
    }
    // eslint-disable-next-line camelcase
    this.selectedFilters.course_ids = courses.map(c => c.id);
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  doLocalFilterCourses(coursesIds: string[]): void {
    if (this.isResetingFilters) {
      return;
    }
    // eslint-disable-next-line camelcase
    this.selectedFilters.course_ids = coursesIds;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  doFilterIlts(ilts: ILTCourse[]): void {
    if (this.isResetingFilters) {
      return;
    }
    // eslint-disable-next-line camelcase
    this.selectedFilters.ilt_course_ids = ilts.map(c => c.id);
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
    if (this.filters?.includes(ToolbarFiltersTypes.trainingsEvents)) {
      this.filterableEvents = [];
      ilts.forEach(ilt => {
        if (ilt.iltCourseEvents) {
          this.filterableEvents = this.filterableEvents.concat(
            ilt.iltCourseEvents,
          );
        }
      });
    }
  }

  doLocalFilterIlts(ilts: string[]): void {
    if (this.isResetingFilters) {
      return;
    }
    // eslint-disable-next-line camelcase
    this.selectedFilters.ilt_course_ids = ilts;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  doFilterLP(lps: LearningPath[]): void {
    if (this.isResetingFilters) {
      return;
    }
    // eslint-disable-next-line camelcase
    this.selectedFilters.learning_path_ids = lps.map(c => c.id);
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  doFilterLPLocal(lpsIds: string[]): void {
    if (this.isResetingFilters) {
      return;
    }
    // eslint-disable-next-line camelcase
    this.selectedFilters.learning_path_ids = lpsIds;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  doFilterCategories(courseCategories: string[]): void {
    if (this.isResetingFilters) {
      return;
    }
    // eslint-disable-next-line camelcase
    this.selectedFilters.course_category_ids = courseCategories;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onAuthorToggleChange($event: MatSlideToggleChange): void {
    this.selectedFilters.authorChecked = $event.checked || undefined;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onEmailTypeFilter(type: MatSelectChange): void {
    if (this.isResetingFilters) {
      return;
    }
    // eslint-disable-next-line camelcase
    this.filterCustomEmailsType = type.value;
    this.selectedFilters.selectedEmailType = type.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onHireStartDateFilter(event: MatDatepickerInputEvent<Date>): void {
    if (this.isResetingFilters) {
      return;
    }
    const startDate = this.datePipe.transform(event.value, 'yyyy/MM/dd');
    if (startDate) {
      this.startDate = new Date(startDate);
    }
    // eslint-disable-next-line camelcase
    this.selectedFilters.after_hire_date = startDate;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onHireEndDateFilter(event: MatDatepickerInputEvent<Date>): void {
    if (this.isResetingFilters) {
      return;
    }
    const endDate = this.datePipe.transform(event.value, 'yyyy/MM/dd');
    if (endDate) {
      this.endDate = new Date(endDate);
    }
    // eslint-disable-next-line camelcase
    this.selectedFilters.before_hire_date = endDate;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onTalentStatusFilter(status: MatSelectChange): void {
    if (this.isResetingFilters) {
      return;
    }
    // eslint-disable-next-line camelcase
    this.selectedFilters.status = status.value;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  changeCheckUsersWithUsername(event: MatSlideToggleChange): void {
    this.checkUsersWithUsername = event.checked;
    // eslint-disable-next-line camelcase
    this.selectedFilters.username = event.checked;
    this.updateCounter(this.selectedFilters);
    this.doFilters.emit(this.selectedFilters);
  }

  onResetFilters(emitReset = true): void {
    this.isResetingFilters = true;
    this.authorChecked = false;
    if (this.courseFilter) {
      this.courseFilter.multiSelect.value = [];
    }
    if (this.courseLocalFilter) {
      this.courseLocalFilter.multiSelect.value = [];
    }
    if (this.iltCourseLocalFilter) {
      this.iltCourseLocalFilter.multiSelect.value = [];
    }
    if (this.categoryLocalFilter) {
      this.categoryLocalFilter.multiSelect.value = [];
    }
    if (this.iltCourseFilter) {
      this.iltCourseFilter.multiSelect.value = [];
    }
    if (this.learningPathFilter) {
      this.learningPathFilter.multiSelect.value = [];
    }
    if (this.learningPathLocalFilter) {
      this.learningPathLocalFilter.multiSelect.value = [];
    }
    if (this.courseActivityFilter) {
      this.courseActivityFilter.multiSelect.value = [];
    }
    if (this.memberFilter) {
      this.memberFilter.multiSelect.value = [];
    }
    if (this.eventsFilter) {
      this.eventsFilter.multiSelect.value = [];
    }
    if (this.scheduleFilter) {
      this.scheduleFilter.multiSelect.value = [];
    }
    if (this.scheduleCohortsFilter) {
      this.scheduleCohortsFilter.multiSelect.value = [];
    }
    if (this.customEmailNameSearch) {
      this.customEmailNameSearch.multiSelect.value = [];
    }
    if (this.groupFilter) {
      this.groupFilter.multiSelect.value = [];
    }
    if (this.filterLanguage) {
      this.filterLanguage = [];
    }

    this.selectedCourseStatus = [
      CourseProgressStatus.new,
      CourseProgressStatus.running,
    ];
    this.selectedTrainingStatus = [
      ILTCourseStatus.assigned,
      ILTCourseStatus.subscribed,
    ];
    this.talentStatusFilter = this.defaultTalentStatus;
    this.checkUsersWithUsername = false;
    this.selectedFilters = {};
    this.trainingType = null;
    this.filterCount = 0;
    this.filterableEvents = [];
    this.startDate = new Date('');
    this.endDate = new Date('');

    this.isResetingFilters = false;

    this.filterCounter.emit(this.filterCount);
    if (emitReset) {
      this.resetFilters.emit();
    }
  }

  compareEmailTypes(
    option: Record<string, string>,
    selection: Record<string, string>,
  ): boolean {
    return option.key === selection.key;
  }

  private setInitialFilters(storeItem: string): void {
    this.storedTrainingsFilters = this.storeService.getItem(storeItem);
    if (!this.storedTrainingsFilters?.filter) {
      this.onResetFilters(false);
      return;
    }
    if (
      this.storedTrainingsFilters?.filter?.author ||
      this.storedTrainingsFilters?.filter?.own
    ) {
      this.authorChecked = true;
      this.selectedFilters.author = this.authorChecked;
    }
    this.filterLanguage =
      this.storedTrainingsFilters.filter?.language ||
      this.storedTrainingsFilters.filter?.email_language ||
      null;
    if (this.filterLanguage) {
      this.selectedFilters.language = this.filterLanguage;
    }
    if (this.storedTrainingsFilters.filter?.before_hire_date) {
      this.endDate = new Date(
        this.storedTrainingsFilters.filter.before_hire_date,
      );
    }
    if (this.storedTrainingsFilters.filter?.after_hire_date) {
      this.startDate = new Date(
        this.storedTrainingsFilters.filter.after_hire_date,
      );
    }
    if (this.storedTrainingsFilters.filter?.username) {
      this.checkUsersWithUsername = true;
    }
    if (this.storedTrainingsFilters?.filter?.status) {
      this.talentStatusFilter = this.storedTrainingsFilters?.filter?.status;
    }

    if (this.storedTrainingsFilters?.filter) {
      this.selectedFilters = this.storedTrainingsFilters?.filter;
      this.updateCounter(this.storedTrainingsFilters?.filter);
    }
  }

  private initFilters(): void {
    if (this.storeId) {
      this.setInitialFilters(this.storeId);
    }
    this.loadCategories();
  }

  private loadCategories(): void {
    if (
      this.filters?.includes(ToolbarFiltersTypes.categories) &&
      !this.allCategories.length
    ) {
      this.setAllCategories();
    }
  }

  private setAllCategories(): void {
    if (!this.isCategoriesLoading) {
      this.isCategoriesLoading = true;
      this.datastore
        .findAll(CourseCategory, {
          page: {
            size: 250,
          },
        })
        .pipe(take(1))
        .subscribe(categories => {
          this.allCategories = CourseCategoryHelper.sortCourseCategories(
            categories.getModels(),
          );
          this.isCategoriesLoading = false;
        });
    }
  }

  private updateCounter(filters: any): void {
    const currentCount = Object.keys(filters).filter(
      key =>
        filters[key] !== null &&
        ((typeof filters[key] === 'object' && filters[key].length !== 0) ||
          (typeof filters[key] !== 'object' &&
            typeof filters[key] !== 'undefined')),
    ).length;
    if (currentCount !== this.filterCount) {
      this.filterCount = currentCount;
      this.filterCounter.emit(this.filterCount);
    }
  }
}
