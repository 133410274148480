/* tslint:disable:no-any */
import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Country } from './country.model';

@JsonApiModelConfig({
  type: 'physical_locations',
})
export class PhysicalLocation extends JsonApiModel {
  @Attribute()
  name!: string;

  @Attribute()
  capacity!: number;

  @Attribute({ serializedName: 'post_code' })
  postCode!: string;

  @Attribute()
  address!: string;

  @Attribute()
  city!: string;

  @Attribute({ serializedName: 'country_id' })
  countryId!: string;

  @Attribute()
  email!: string | undefined;

  @BelongsTo()
  country!: Country;
}
