<div>
  <mat-form-field appearance="outline" [class.disable]="disabled" [ngClass]="{ 'mat-form-field-warning mat-form-field-outline-warning': showWarning }" [floatLabel]="extraOption ? 'always' : 'auto'">
    <mat-label>
      {{ placeholder }}
    </mat-label>
    <mat-select  
      [required]="required"
      [placeholder]="extraOption || ''"
      disableOptionCentering
      panelClass="multi-select-server-side-select-panel"
      [formControl]="multiServerSideCtrl"
      [multiple]="multiple"
      (openedChange)="panelChange($event)"
      #multiSelect
    >
      <mat-select-trigger>
        {{ this.multiple ? (multiServerSideCtrl.value && multiServerSideCtrl.value[0]
            ? multiServerSideCtrl.value[0].name : placeholder) : (multiServerSideCtrl.value?.name || placeholder)
        }}
        <span *ngIf="multiServerSideCtrl.value?.length > 1" class="additional-selection">
          (+{{multiServerSideCtrl.value.length - 1}} {{multiServerSideCtrl.value?.length === 2
            ? ('common.other' | translate)
            : ('common.others' | translate)
          }})
        </span>
      </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search
          [disableScrollToActiveOnOptionsChanged]="true"
          [noEntriesFoundLabel]="noEntriesFoundLabel"
          [placeholderLabel]="placeholderLabel"
          [formControl]="multiServerSideFilteringCtrl"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngIf="!multiple" [value]="undefined">-</mat-option>
      <mat-option *ngFor="let resource of filteredServerSideResources | async" [value]="resource">
        {{getName(resource)}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
