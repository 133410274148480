import { TrainingsFilters } from '@core/constants/training-filters.constants';
import { ToolbarFiltersTypes } from '@core/constants/toolbar-filters.constants';

import {
  RouteActionButtonInterface,
  RouteActionInterface,
} from '../../interfaces/route-action.interface';

const saveButton: RouteActionButtonInterface = {
  action: 'doActionOne',
  label: 'common.save',
  color: 'yellow',
  class: ['filled', 'no-border'],
  name: 'save',
};

const cancelButton: RouteActionButtonInterface = {
  action: 'doActionTwo',
  label: 'common.cancel',
  color: 'darkblue',
  name: 'cancel',
};

const deleteButton: RouteActionButtonInterface = {
  action: 'doActionThree',
  label: 'common.delete',
  color: 'darkblue',
  name: 'delete',
};

const previewButton: RouteActionButtonInterface = {
  action: 'doActionFour',
  label: 'common.preview',
  color: 'darkblue',
  name: 'preview',
};

const saveAndSync: RouteActionButtonInterface = {
  action: 'doActionOne',
  label: 'settings.organization.saveAndSync',
  color: 'yellow',
  class: ['filled', 'no-border'],
  name: 'saveAndSync',
};

const newItemButton = (
  key: string,
  routerLink = '',
  canBeHidden = false,
): RouteActionButtonInterface => ({
  label: key,
  color: 'yellow',
  class: ['filled', 'no-border'],
  name: 'new',
  icon: 'add',
  routerLink,
  canBeHidden,
});

export const routeActions: RouteActionInterface[] = [
  {
    // Routes with navigation via toolbar
    routeNames: ['/courses/list'],
    buttons: [
      {
        action: 'importCourse',
        label: '',
        icon: 'cloud_upload',
        color: 'darkblue',
        name: 'importCourse',
        class: ['import-course'],
      },
      newItemButton('toolbar.newCourse', '/courses/new/profile', true),
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.courses,
      ToolbarFiltersTypes.categories,
      ToolbarFiltersTypes.languages,
      ToolbarFiltersTypes.author,
    ],
    storeId: TrainingsFilters.coursesFilters,
  },
  {
    routeNames: ['/categories/list'],
    buttons: [
      newItemButton('toolbar.newCategory', '/categories/new/profile'),
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [ToolbarFiltersTypes.categories],
    storeId: TrainingsFilters.categoriesFilters,
  },
  {
    routeNames: ['/learning-paths/list'],
    buttons: [
      newItemButton(
        'toolbar.newLearningPath',
        '/learning-paths/new/profile',
        true,
      ),
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.learningPaths,
      ToolbarFiltersTypes.categories,
      ToolbarFiltersTypes.languages,
      ToolbarFiltersTypes.author,
    ],
    storeId: TrainingsFilters.learningPathsFilters,
  },
  {
    routeNames: ['/ilt-courses/list'],
    buttons: [
      newItemButton(
        'toolbar.newLiveTraining',
        '/ilt-courses/new/profile',
        true,
      ),
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.trainings,
      ToolbarFiltersTypes.categories,
      ToolbarFiltersTypes.languages,
      ToolbarFiltersTypes.author,
    ],
    storeId: TrainingsFilters.trainingsFilters,
  },
  {
    routeNames: ['/ilt-courses/instructors-list'],
    buttons: [
      {
        action: 'doActionOne',
        label: 'common.today',
        color: 'darkblue',
        class: [],
        name: 'addPeople',
        icon: 'today',
      },
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.trainings,
      ToolbarFiltersTypes.trainingsEvents,
    ],
    storeId: TrainingsFilters.instructorsFilters,
  },
  {
    routeNames: ['/courses/task-activity-review'],
    buttons: [
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.courses,
      ToolbarFiltersTypes.courseActivities,
      ToolbarFiltersTypes.members,
    ],
    storeId: TrainingsFilters.taskReviewFilters,
  },
  {
    routeNames: ['/courses/my'],
    buttons: [
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.coursesLocal,
      ToolbarFiltersTypes.categories,
      ToolbarFiltersTypes.courseStatus,
      ToolbarFiltersTypes.languages,
    ],
    storeId: TrainingsFilters.myCoursesFilters,
    sticky: true,
  },
  {
    routeNames: ['/ilt-courses/my'],
    buttons: [
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.trainingsLocal,
      ToolbarFiltersTypes.categories,
      ToolbarFiltersTypes.trainingStatus,
      ToolbarFiltersTypes.languages,
    ],
    storeId: TrainingsFilters.myIltCoursesFilters,
    sticky: true,
  },
  {
    routeNames: ['/learning-paths/my'],
    buttons: [
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.learningPathsLocal,
      ToolbarFiltersTypes.categories,
      ToolbarFiltersTypes.courseStatus,
      ToolbarFiltersTypes.languages,
    ],
    storeId: TrainingsFilters.myLearningPathsFilters,
    sticky: true,
  },
  {
    routeNames: ['/courses/catalogue'],
    buttons: [
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.type,
      ToolbarFiltersTypes.trainingsLocal,
      ToolbarFiltersTypes.coursesLocal,
      ToolbarFiltersTypes.learningPathsLocal,
      ToolbarFiltersTypes.categories,
      ToolbarFiltersTypes.courseStatus,
      ToolbarFiltersTypes.languages,
    ],
    storeId: TrainingsFilters.trainingCatalogueFilters,
    sticky: true,
  },
  {
    routeNames: ['/training-schedules/list'],
    buttons: [
      newItemButton('toolbar.newSchedule', '/training-schedules/new/profile'),
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.trainingSchedules,
      ToolbarFiltersTypes.languages,
      ToolbarFiltersTypes.author,
    ],
    storeId: TrainingsFilters.trainingScheduleFilters,
  },
  {
    routeNames: [/^\/training-schedules\/[0-9]+\/cohorts$/],
    buttons: [
      newItemButton('toolbar.newCohort'),
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [ToolbarFiltersTypes.cohorts],
    storeId: TrainingsFilters.trainingScheduleCohortsFilters,
  },
  {
    routeNames: ['/settings/custom-email/list'],
    buttons: [
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.languages,
      ToolbarFiltersTypes.emailTemplates,
      ToolbarFiltersTypes.emailTemplatesType,
    ],
    storeId: TrainingsFilters.emailTemplatesFilters,
  },
  {
    routeNames: ['/organizations/list', /^\/organizations\/[0-9]+\/members$/],
    buttons: [],
  },
  {
    routeNames: [/^\/organizations\/[0-9]+\/trainings$/],
    buttons: [
      {
        action: 'doActionOne',
        label: 'organization.trainings.assign',
        color: 'yellow',
        class: ['filled', 'no-border'],
        name: 'assignTrainings',
      },
    ],
  },
  {
    routeNames: [/^\/organizations\/[0-9]+\/permissions$/],
    buttons: [
      {
        action: 'doActionOne',
        label: 'organization.permissions.addUser',
        color: 'darkblue',
        class: [],
        name: 'addPeople',
        icon: 'group_add',
      },
      {
        action: 'doActionTwo',
        label: 'common.save',
        color: 'yellow',
        class: ['filled', 'no-border'],
        name: 'savePermissions',
      },
    ],
  },
  {
    routeNames: ['/talents/list'],
    buttons: [
      {
        action: 'inviteByEmail',
        label: 'toolbar.inviteEmail',
        icon: 'mail',
        color: 'darkblue',
        canBeHidden: true,
      },
      {
        action: 'inviteByCsv',
        label: 'toolbar.inviteCsv',
        icon: 'attach_file',
        color: 'darkblue',
        canBeHidden: true,
      },
      newItemButton('toolbar.newMember', '/talents/new', true),
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [
      ToolbarFiltersTypes.members,
      ToolbarFiltersTypes.groups,
      ToolbarFiltersTypes.hireDate,
      ToolbarFiltersTypes.memberStatus,
      ToolbarFiltersTypes.checkUserName,
    ],
    storeId: TrainingsFilters.peoplePeopleFilters,
  },
  {
    routeNames: ['/groups/list'],
    buttons: [
      newItemButton('toolbar.newGroup', '/groups/new/profile'),
      {
        action: 'showFilters',
        label: 'common.filters',
        icon: 'filter_list',
        color: 'darkblue',
        name: 'filters',
        class: ['show-filters'],
      },
    ],
    filters: [ToolbarFiltersTypes.groups],
    storeId: TrainingsFilters.peopleGroupFilters,
  },
  {
    routeNames: ['/settings/certificates'],
    buttons: [newItemButton('common.new', '/settings/certificates/new')],
  },
  {
    routeNames: ['/settings/learning-history'],
    buttons: [newItemButton('common.new', '/settings/learning-history/new')],
  },
  {
    routeNames: ['/settings/integrations'],
    buttons: [newItemButton('common.new', '/settings/integrations/new')],
  },
  {
    routeNames: ['/settings/branding/list'],
    buttons: [newItemButton('common.new', '/settings/branding/new', true)],
  },
  {
    routeNames: ['/settings/news/list'],
    buttons: [newItemButton('common.new', '/settings/news/new', true)],
  },
  {
    routeNames: [
      '/reporting/groups',
      '/reporting/talents',
      '/reporting/trainings',
      '/reporting/courses',
      '/reporting/ilt-courses',
      '/reporting/learning-paths',
      '/reporting/schedules',
      /^\/reporting\/courses\/\d+(?:&?[^=&]*=[^=&]*)*$/,
      /^\/reporting\/learning-paths\/\d+(?:&?[^=&]*=[^=&]*)*$/,
      /^\/reporting\/ilt-courses\/\d+(?:&?[^=&]*=[^=&]*)*$/,
      /^\/reporting\/schedules\/\d+\/cohorts$/,
      /^\/reporting\/schedules\/\d+\/cohorts\/\d+\/members$/,
      /^\/reporting\/talents\?.*/,
      /^\/reporting\/courses\?.*/,
      /^\/reporting\/trainings\?.*/,
      /^\/reporting\/learning-paths\?.*/,
      /^\/reporting\/schedules\?.*/,
      /^\/reporting\/ilt-courses\?.*/,
    ],
    buttons: [],
  },
  // Routes with actions via toolbar
  {
    routeNames: [
      '/categories/new/profile',
      '/groups/new/profile',
      '/talents/new',
      '/my/profile',
      '/settings/branding/new',
      '/settings/news/new',
      /^\/settings\/custom-email\/[0-9]+$/,
    ],
    buttons: [cancelButton, saveButton],
  },
  // Routes with actions via toolbar
  {
    routeNames: [
      '/settings/company',
      '/settings/authentication',
      '/settings/gamification',
    ],
    buttons: [cancelButton, saveButton],
    sticky: true,
  },
  {
    routeNames: [
      /^\/categories\/[0-9]+\/profile$/,
      /^\/groups\/[0-9]+\/profile$/,
      /^\/talents\/[0-9]+$/,
      /^\/settings\/news\/[0-9]+$/,
    ],
    buttons: [cancelButton, deleteButton, saveButton],
  },
  // Settings
  {
    routeNames: [
      '/settings/certificates/new',
      /^\/settings\/certificates\/[0-9]+$/,
    ],
    buttons: [cancelButton, previewButton, saveButton],
    sticky: true,
  },
  {
    routeNames: [
      '/settings/learning-history/new',
      /^\/settings\/learning-history\/[0-9]+$/,
    ],
    buttons: [cancelButton, previewButton, saveButton],
    sticky: true,
  },
  {
    routeNames: [
      '/settings/integrations/new',
      /^\/settings\/integrations\/[0-9]+$/,
    ],
    buttons: [
      cancelButton,
      {
        action: 'doActionThree',
        label: 'settings.integrations.personio.sync',
        color: 'darkblue',
        class: ['filled', 'no-border'],
        name: 'sync',
      },
      {
        action: 'doActionOne',
        label: 'common.save',
        color: 'yellow',
        class: ['filled', 'no-border'],
        name: 'save',
      },
    ],
    sticky: true,
  },
  {
    routeNames: ['/settings/organization'],
    buttons: [cancelButton, saveAndSync],
    sticky: true,
  },
  {
    routeNames: [/^\/settings\/branding\/[0-9]+$/],
    buttons: [cancelButton, deleteButton, saveButton],
    sticky: true,
  },
  {
    routeNames: [/^\/courses\/task-activity-review\/[0-9]+$/],
    buttons: [saveButton],
  },
];
