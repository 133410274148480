<mat-toolbar *ngIf="!!actions" class="top-bar"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             [class.settings]="isSettingsSection()"
             [class.di-none]="actions?.buttons && !actions.buttons?.length && !breadcrumbs.length"
>
  <div class="toolbar-container" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="2px">
    <div class="actions-container" fxLayoutAlign="space-between center">
      <div class="breadcrumbs" fxLayoutAlign="space-between center">
        <div *ngFor="let crumb of breadcrumbs; let index = index;" class="breadcrumb-item">
          <a [routerLink]="crumb.url" [class.current]="index === (breadcrumbs.length - 1)">
            {{ crumb.key | translate: crumb.args }}
          </a>
          <mat-icon *ngIf="index < (breadcrumbs.length - 1)">chevron_right</mat-icon>
        </div>
      </div>
      <div class="cta" fxLayout="row" [fxLayoutGap]="actions?.buttons?.length ? '16px' : '0'">
        <ng-container *ngFor="let button of actions?.buttons">
          <button
            *ngIf="!hideAction(button)"
            mat-stroked-button
            [color]="button.color"
            class="action-button"
            [ngClass]='button?.class || []'
            [disabled]="disableAction(button.name)"
            [routerLink]="getButtonRouterLink(button)"
            (click)="runAction(button.action)"
          >
            <mat-icon *ngIf="button?.icon" class="size-m" aria-hidden="true">{{ button.icon }}</mat-icon>
            {{ (button?.label || '') | translate }}
            <div *ngIf="button?.name === 'filters' && actions?.filters && filterCounter > 0" fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="8px" class="filters-counter">
              <div>{{ filterCounter }}</div>
            </div>
            <mat-icon *ngIf="button?.name === 'filters' && expandFilters" class="size-m">expand_less</mat-icon>
            <mat-icon *ngIf="button?.name === 'filters' && !expandFilters" class="size-m">expand_more</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
    <app-toolbar-filters
      [filters]="actions?.filters"
      [storeId]="actions?.storeId"
      [expandFilters]="expandFilters"
      (doFilters)="doApplyFilters($event)"
      (resetFilters)="doResetFilters($event)"
      (filterCounter)="onFilterCounter($event)"
      class="toolbar-filters"
    >
    </app-toolbar-filters>
  </div>
</mat-toolbar>
