export enum TrainingsFilters {
  coursesFilters = 'TrainingCourses',
  trainingsFilters = 'TrainingsTrainings',
  learningPathsFilters = 'TrainingsLearningPaths',
  categoriesFilters = 'TrainingsCategories',
  instructorsFilters = 'TrainingsInstructors',
  myIltCoursesFilters = 'TrainingMyTrainings',
  myLearningPathsFilters = 'TrainingsMyLearningPaths',
  // The following two are mistakenly inversed and kept like this because of backwards compatibility
  myCoursesFilters = 'TrainingMyCourses',
  trainingCatalogueFilters = 'TrainingsTrainingCatalogue',
  peoplePeopleFilters = 'PeoplePeople',
  peopleGroupFilters = 'PeopleGroup',
  organizationAssignCheckSubunits = 'OrganizationAssignCheckSubunits',
  orgaMemberFilters = 'OrgaMembers',
  orgaTrainingFilters = 'OrgaTrainings',
  taskReviewFilters = 'TaskReviews',
  trainingScheduleFilters = 'TrainingSchedule',
  trainingScheduleCohortsFilters = 'TrainingScheduleCohorts',

  integrationsTable = 'IntegrationsTable',
  brandingsTable = 'BrandingsTable',
  newsTable = 'NewsTable',
  certificatesTable = 'CertificatesTable',
  learningHistoryTable = 'LearningHistoryTable',
  emailTemplatesFilters = 'emailTemplatesFilters',

  reportingGroupsTable = 'ReportingGroupsTable',
  reportingMembersTable = 'ReportingMembersTable',
  reportingTrainingsTable = 'ReportingTrainingsTable',
  reportingCoursesTable = 'ReportingCoursesTable',
  reportingLearningPathsTable = 'ReportingLearningPathsTable',
  reportingIltCoursesTable = 'ReportingIltCoursesTable',
  reportingSchedulesTable = 'ReportingSchedulesTable',
  reportingCohortsTable = 'ReportingCohortsTable',
  reportingCohortMembersTable = 'ReportingCohortMembersTable',
}
