import {
  Attribute,
  BelongsTo,
  HasMany,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { Member } from '../member.model';
import { TrainingScheduleItem } from '@models/training-schedule/training-schedule-item.model';
import { getTrainingScheduleTimeString } from '@app/core/helper/training-schedule';

@JsonApiModelConfig({
  type: 'schedules',
})
export class TrainingSchedule extends JsonApiModel {
  @Attribute({ serializedName: 'created_at' })
  createdAt: Date | undefined;

  @Attribute({ serializedName: 'updated_at' })
  updatedAt!: Date;

  @Attribute()
  name!: string;

  @Attribute()
  description!: string;

  @Attribute()
  language!: string;

  @Attribute({ serializedName: 'number_of_days' })
  numberOfDays!: number;

  @Attribute({ serializedName: 'number_of_cohorts' })
  numberOfCohorts!: number;

  @Attribute({ serializedName: 'start_time' })
  startTime!: string;

  @Attribute({ serializedName: 'end_time' })
  endTime!: string;

  @Attribute({ serializedName: 'requires_ms_login', readonly: true })
  requiresMSLogin!: boolean;

  @BelongsTo()
  owner!: Member;

  @HasMany({ key: 'schedule_items' })
  items!: TrainingScheduleItem[];

  @HasMany({ key: 'editors' })
  editors!: Member[];

  public get startTimeFormatted(): string | null {
    if (!this.startTime) {
      return null;
    }

    return getTrainingScheduleTimeString(this.startTime);
  }

  public get endTimeFormatted(): string | null {
    if (!this.endTime) {
      return null;
    }

    return getTrainingScheduleTimeString(this.endTime);
  }
}
