import {
  Attribute,
  BelongsTo,
  JsonApiModel,
  JsonApiModelConfig,
} from '@elearnio/angular2-jsonapi';

import { environment } from '@environment';
import { CompanySetting } from '@models/company-setting.model';
import { Member } from '@models/member.model';

@JsonApiModelConfig({
  baseUrl: `${environment.backendBaseUrl}/api/v1`,
  type: 'api_configs',
})
export class Integration extends JsonApiModel {
  @Attribute()
  name!: string;

  @Attribute({ serializedName: 'api_type' })
  type!: string;

  @Attribute({ serializedName: 'client_id' })
  clientId!: string;

  @Attribute({ serializedName: 'tenant_id' })
  tenantId!: string | undefined;

  @Attribute({ serializedName: 'client_secret' })
  clientSecret!: string;

  @Attribute({ serializedName: 'impersonating_email' })
  impersonatingEmail!: string | undefined;

  @Attribute({ serializedName: 'google_configuration' })
  credentialsFile!: string | undefined;

  @Attribute({ serializedName: 'send_invites' })
  sendInvites!: boolean;

  @Attribute({ serializedName: 'custom_attributes_enabled' })
  customAttributesEnabled!: boolean;

  @Attribute({ serializedName: 'send_certificates' })
  sendCertificates!: boolean;

  @Attribute({ serializedName: 'mapped_custom_attributes' })
  mappedCustomAttributes!: any;

  @Attribute({ serializedName: 'group_by' })
  groupBy!: string;

  @Attribute({ serializedName: 'created_at' })
  createdAt!: Date;

  @Attribute({ serializedName: 'updated_at' })
  lastSynced!: Date;

  @Attribute({ serializedName: 'company_setting_id' })
  companySettingId!: number;

  @Attribute({ serializedName: 'calendar_enabled' })
  calendarEnabled!: boolean;

  @Attribute({ serializedName: 'teams_link_enabled' })
  teamsLinkEnabled!: boolean;

  @Attribute({ serializedName: 'event_prefix' })
  eventPrefix!: string;

  @Attribute({ serializedName: 'channel_enabled' })
  channelEnabled!: boolean;

  @Attribute({ serializedName: 'availability_check_enabled' })
  enableAvailability!: boolean;

  @BelongsTo({ key: 'created_by' })
  createdBy!: Member;

  @BelongsTo({ key: 'company_setting' })
  companySetting!: CompanySetting;
}
